import React, {useState, useEffect} from "react";
import cn from "classnames";
import { IndividualResourceCard } from "src/components/solutions/Resources";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import useResourceOverviewData from "src/hooks/useResourceOverviewData";
import { LocalizedLink,SEO,Breadcrumbs } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import { getMetaDescByResourceType, getUkMetaDescByResourceType } from "src/components/helpers/getMetaDescriptionResources";

const MainResourcesPageTemplate = ({ location, pageContext, path }) => {
  const {
    basePath,
    title,
    hrefLangData,
    locale,
    locales,
    drupalResourceType
  } = pageContext;
  const [currentPage, setCurrentPage] = useState(1);

  let cardsPerPage = 50;
  let array =[]
  const splitIntoChunk = (arr, chunk) => {
    for (let i=0; i < arr.length; i += chunk) {
        let tempArray;
        tempArray = arr.slice(i, i + chunk);
        array.push(tempArray)
    }
  }


  let nodes = useResourceOverviewData(locale);
  nodes = nodes?.filter(x => x?.context?.type?.id === drupalResourceType)
  const numPages = Math.ceil(nodes.length / cardsPerPage)
  let newNumCards = nodes.length
 
  splitIntoChunk(nodes, 50)
  const resources = createRelatedResources(array[currentPage-1], {
    hasDescription: true,
  });

  const crumbs = [
    { title: "Home", link: (locale == 'en') ? "/" : `/${locales[locale].path}` },
    {
      title: "Resources",
      link: (locale == 'en') ? "/resources" : `/${locales[locale].path}/resources`,
    },
  ];
  if (currentPage > 1) {
    crumbs.push({ title: `${title} (Page ${currentPage})` });
  } else {
    crumbs.push({ title });
  }

  const initial = (currentPage - 1) * cardsPerPage + 1;
  let final = currentPage * cardsPerPage;
  if (final > newNumCards) final = newNumCards;
  const viewingRange = `${initial} - ${final}`;

  let hreflangAlternatives = []

  hrefLangData.map((data) => {
    hreflangAlternatives.push({
      href: data.path,
      hreflang: data.lang
    })
    if (data.lang == 'en') {
      hreflangAlternatives.push({
        href: data.path,
        hreflang: "x-default"
      })
    }
  })

  const isUKPage = locales[locale].path == 'uk' ? true : false;
  const metaDescription = isUKPage ? getUkMetaDescByResourceType(pageContext.title) : getMetaDescByResourceType(pageContext.title)
  
  
  const OGMarkup = getOGMarkup(`All ${pageContext.title}`, pageContext.title);
  const metatags = [
    { name: 'title', content: `All ${pageContext.title} | Accruent` },
    {name:'description', content: `${metaDescription}`}
  ]

  const search = location.search;
  hreflangAlternatives = search.length > 0 ? [] : hreflangAlternatives
  const pageDataPath = getPageDataJsonPath(basePath)
  return (
    <section className="section">
      <SEO
        lang={locale}
        pageUrl={basePath}
        title={`All ${pageContext.title}`}
        meta={metatags}
        alternatives={hreflangAlternatives}
        OGMarkup={OGMarkup}
        preloads= {[pageDataPath]}
      />
      <div className="container">
        {/* BACK LINK */}
        <section className="section">
          <LocalizedLink to={(locale == 'en') ? "/resources" : `/${locales[locale].path}/resources`}>
            <span className="icon-text is-flex is-align-items-center">
              <span className="icon">
                <i className="far fa-chevron-left"></i>
              </span>
              <span>Back to Resources</span>
            </span>
          </LocalizedLink>
        </section>

        {/* BREADCRUMBS */}
        <section className="section">
          <Breadcrumbs crumbs={crumbs} />
        </section>

        {/* VIEWEING RANGE */}
        <section className="section has-text-centered">
          {/* PAGINATION */}
          <h1 className="title title-2">{pageContext.title}</h1>
          <p>{`Viewing ${viewingRange} of ${newNumCards}`}</p>
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </section>

        {/* CONTENT */}
        <section className="section resource-overview-cards">
          <div className="tw-flex tw-flex-wrap tw-gap-5">
            {resources.map((resource) => (
              <IndividualResourceCard
                data={resource}
                shouldNotResetFilterWhenClickingLink
                key={resource.uuid}
              />
            ))}
          </div>
        </section>

        {/* PAGINATION */}
        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </section>
  );
};

export default MainResourcesPageTemplate;
const Pagination = ({ numPages, currentPage,setCurrentPage }) => {
  if (numPages > 1) {
    return (
      <section className="section">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <nav
              className="pagination"
              role="navigation"
              aria-label="pagination"
            >
              <ul className="pagination-list">
                {Array.from({ length: numPages }).map((_, i) => {
                  return (
                    <li key={`pagination-item-${i}`}>
                      <LocalizedLink
                        className={cn("pagination-link", {
                          "is-current": i + 1 === currentPage,
                        })}
                        aria-label={`Goto page ${i + 1}`}
                        onClick={() =>  setCurrentPage(i+1)}
                      >
                        {i + 1}
                      </LocalizedLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </section>
    );
  } else return null;
};
