export const getMetaDescByResourceType = (type) => {

  switch(type) {
    case 'Articles': {
        return 'Read through expert articles from Accruent on how to maximize and optimize the facilities and asset management lifecycle.';
    }
    case "Blog Posts": {
        return 'Read through expert blog posts on how to optimize the facilities and asset management lifecycle and unify your built environment.';
    }
    case "Brochures": {
        return 'Read through brochures covering a range of topics from Accruent products to industry best practices and learn how to unify your built environment.';
    }
    case "Case Studies": {
        return 'Read through our case studies to see how Accruent\'s products and software solutions have helped global companies unify their built environments.';
    }
    case "Company News": {
        return 'Get the latest company and industry news from Accruent, a Fortive company.';
    }
    case "eBooks": {
        return 'Download free eBooks from Accruent for expert advice on how to optimize your facilities and asset management and unify your built environment.';
    }
    case "Knowledge Hub": {
        return 'Explore our Knowledge Hub for tips and best practices on how to maximize the lifecycle of your facilities and assets and unify your built environment.';
    }
    case "Podcasts": {
        return 'Tune in to podcasts featuring Accruent employees and industry leaders who discuss how to maximize the lifecycle of your facilities and assets.';
    }
    case "Press Releases": {
        return 'Read through our press releases to get the latest product and company news from Accruent, a Fortive company.';
    }
    case "Videos": {
        return 'Watch our videos to learn more about how Accruent products can help optimize your facilities and asset management and unify your built environment.';
    }
    case "Webinars": {
        return 'Download our on-demand webinars to hear industry experts discuss best practices and updates on Accruent products.';
    }
    case "White Papers": {
        return 'Download Accruent white papers, covering topics ranging from industry trends and best practices to using Accruent products to unify your built environment.';
    }
    case "Infographics": {
        return 'Browse our collection of informative infographics for insights on industry trends, data visualization, and expert knowledge at a glance.';
    }
    default: {
        return 'Accruent\'s software helps workplace & asset management organizations unify their built environments for better management of people, places, and resources.';
    }
 }
}


export const getUkMetaDescByResourceType = (type) => {

    switch(type) {
      case 'Articles': {
          return 'Browse through articles from Accruent on how to maximise and optimise the facilities and asset management lifecycle.';
      }
      case "Blog Posts": {
          return 'Read blog posts from the experts at Accruent for best practices and tips on how to optimise the facilities and asset management lifecycle.';
      }
      case "Brochures": {
          return 'Learn how to unify your built environment through Accruent brochures covering a range of topics from product tips to industry best practices.';
      }
      case "Case Studies": {
          return 'Peruse case studies to see how Accruent\'s products and software solutions have helped companies around the world unify their built environments.';
      }
      case "Company News": {
          return 'Get the latest company news and industry news from Accruent, a global Fortive operating company.';
      }
      case "eBooks": {
          return 'Learn how to optimise your facilities and asset management and unify your built environment with free downloadable eBooks from Accruent.';
      }
      case "Knowledge Hub": {
          return 'Explore our Knowledge Hub for best practices on how to maximise the lifecycle of your facilities and assets.';
      }
      case "Podcasts": {
          return 'Listen to podcasts featuring Accruent employees and industry leaders discussing how to unify the built environment and maximise facility lifecycles.';
      }
      case "Press Releases": {
          return 'Read through Accruent press releases to get the latest product and company news from the global facilities and asset mangagement company.';
      }
      case "Videos": {
          return 'Watch short videos from Accruent to learn how our products can help optimise your facility management and maintenance operations.';
      }
      case "Webinars": {
          return 'Download our webinars to hear Accruent employees and industry experts discuss new products and best practices for facility management and maintenance.';
      }
      case "White Papers": {
          return 'Download Accruent white papers, which cover industry trends, facility management best practices, and how Accruent products can unify your built environment.';
      }
      case "Infographics": {
          return 'Explore Accruent\'s insightful infographics for a quick, visual understanding of industry trends and data. Perfect for fast knowledge absorption!';
      }
      default: {
          return 'Accruent\'s software helps workplace & asset management organizations unify their built environments for better management of people, places, and resources.';
      }
   }
  }